import axios from "axios";

const fetchCharity = async (charity) => {
  const resp = await axios.get(`/api/campaigns/zch2020/charities/${charity}`);
  return resp.data;
};

const fetchCharities = async (country) => {
  const resp = await axios.get(
    `/api/campaigns/zch2020/charities?country=${country}&tier=gold`
  );
  return resp.data;
};

export default {
  fetchCharities,
  fetchCharity,
};
